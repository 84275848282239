import React, { useEffect } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import SEOHelmet from "../components/SEOHelmet";
import { aboutBannerText, missionText, aboutText2 } from "../constants";
import SecondAboutImageSource from "../images/fleetbanner.jpeg";
import AboutImageSource from "../images/limo2.jpg";
import AboutBannerImg from "../images/aboutbanner.jpeg";
import OverlayContent from "../components/Banner/OverlayContent";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import ButtonLink from "../components/ButtonLink";
import scrollTo from "gatsby-plugin-smoothscroll";

const AboutPage = ({ location }) => {
  useEffect(() => {
    if (location?.state?.scrollToTop) {
      scrollTo("#top");
    }
  }, []);

  const subTitle = () => <div>{aboutBannerText}</div>;

  return (
    <AboutContainer id="top">
      <SEOHelmet
        title="Memories Limousine | About Us"
        description="Learn all about memories limousine"
      />
      <Header />
      <Banner img={AboutBannerImg} showOverlay>
        <OverlayContent title={"About us"} subTitle={subTitle} />
      </Banner>
      <AboutFirst>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Title>Who Are We?</Title>
            <Text>{missionText} </Text>
            <ButtonLink
              to="/services"
              state={{ scrollToTop: true }}
              text="View Services"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ImageContainer>
              <Image src={AboutImageSource} alt="Limousine driver steering" />
            </ImageContainer>
          </Grid>
        </Grid>
      </AboutFirst>
      <AboutSecond>
        <Grid container spacing={2}>
          <Hidden mdDown>
            <Grid item xs={12} md={6}>
              <ImageContainer>
                <Image src={SecondAboutImageSource} alt="Limousine on road" />
              </ImageContainer>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <AboutSecondTitle>A Ride to Remember</AboutSecondTitle>
            <AboutSecondText>{aboutText2} </AboutSecondText>
            <ButtonLink
              state={{ scrollToTop: true }}
              to="/contact"
              text="Contact Us"
            />
          </Grid>
        </Grid>
      </AboutSecond>
      <Footer />
    </AboutContainer>
  );
};

/*--------------Styling-----------------*/

const AboutSecond = styled.div`
  background: #383838;
  padding: 50px;
  @media (max-width: 960px) {
    padding: 50px 0;
  }
  color: #383838;
  text-align: left;
`;

const AboutContainer = styled.div`
  background-color: #fff;
`;

const Title = styled.h1`
  font-weight: 400;
  color: black;
  text-align: center;
  margin-top: 60px;
`;

const AboutFirst = styled.div`
  background: whitesmoke;
  vertical-align: top;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const Text = styled.div`
  font-weight: 200;
  line-height: 30px;
  margin: auto;
  max-width: 800px;
  font-size: 21px;
  padding: 0px 20px;
  color: black;
  width: 95%;
  text-align: center;
`;

const Image = styled.img`
  max-width: 750px;
  max-height: 414px;
  width: 100%;
  @media (max-width: 960px) {
    width: 95%;
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const AboutSecondTitle = styled.h2`
  color: white;
  font-weight: 400;
  text-align: center;
  margin-top: 60px;
  font-size: 32px;
`;

const AboutSecondText = styled(Text)`
  color: lightgrey;
  text-align: center;
`;
export default AboutPage;
